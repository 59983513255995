<template>
  <div>
    <template v-if="selectedValue">
      <app-text-field
        :label="label"
        :value="selectedValue.name"
        readonly
        @click="open()"
        prepend-inner-icon="mdi-magnify"
        @keypress="open()"
        :rules="rules"
        v-bind="$attrs"
        @click:clear="clear()"
      />
    </template>
    <template v-else>
      <div id="acc" @click.stop="open()">
        <app-text-field
          :label="label"
          readonly
          @click="open()"
          prepend-inner-icon="mdi-magnify"
          v-bind="$attrs"
        />
      </div>
    </template>

    <v-dialog
      v-model="dialog"
      max-width="600px"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between" style="width: 100%">
            <div>
              <span>Selecionar {{ label }}</span>
            </div>

            <v-btn
              v-if="$acl.can('can_store_customers')"
              @click="openForm()"
              color="success"
              icon
              ><v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-subtitle>
          <v-text-field
            :loading="isLoading"
            class="mt-4"
            v-model="searchForm.text"
            solo
            flat
            background-color="grey lighten-4"
            prepend-inner-icon="mdi-magnify"
            placeholder="Pesquisar..."
            hide-details
            autofocus
            v-debounce:300ms="search"
          />
        </v-card-subtitle>
        <v-card-text>
          <app-pagination @click="search($event)" :data="searchResults">
            <v-list>
              <template v-for="(row, index) in searchResults.data">
                <v-list-item :key="index" @click="setSelectedValue(row)">
                  <v-list-item-content>
                    <v-list-item-title>{{ row.name }} </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon> </v-list-item-icon>
                </v-list-item>
                <v-divider :key="'div' + index" />
              </template>
            </v-list>
          </app-pagination>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Voltar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CustomerFormDialog
      @store="setSelectedValue($event)"
      ref="CustomerFormDialog"
    />
  </div>
</template>

<script>
import CustomerFormDialog from "@/components/customers/forms/CustomerFormDialog.vue";
import { customerAlias } from "@/utils/customerAlias";
export default {
  components: { CustomerFormDialog },
  props: {
    value: {},

    label: {
      type: String,
      default: customerAlias(),
    },

    rules: {},
  },

  data: () => ({
    url: "customer/customer",

    searchResults: {
      data: [],
    },

    selectedValue: {
      name: null,
    },

    dialog: false,

    isLoading: false,

    searchForm: {
      text: null,
      status: "active",
    },
  }),

  watch: {
    value() {
      this.selectedValue = this.value;
    },
  },

  created() {
    this.selectedValue = this.value;
  },

  methods: {
    async open() {
      await this.search();

      this.dialog = true;
    },

    async search(page = 1) {
      this.searchForm.page = page;
      this.isLoading = true;
      await this.$http.index(this.url, this.searchForm).then((response) => {
        this.searchResults = response.customers;
      });

      this.isLoading = false;
    },

    setSelectedValue(selectedValue) {
      this.selectedValue = selectedValue;

      this.$emit("input", selectedValue);

      this.dialog = false;
    },

    clear() {
      this.selectedValue = null;
      this.$emit("input", null);
    },

    openForm() {
      this.$refs.CustomerFormDialog.open();
    },
  },
};
</script>
