<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <app-title> Tem certeza que quer deletar essa sessão? </app-title>
      </v-card-title>
      <v-card-text>
        <div>
          <v-switch v-model="form.delete_future_transactions" class="pb-0 mt-0" :true-value="1" :false-value="0"
            hide-details label="Deletar também as transações futuras ?" />
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <app-btn color="error" @click="handleDelete()" rounded> Deletar </app-btn>
        <app-btn outlined rounded @click="dialog = false" text> voltar </app-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    transaction: {},
  },

  data: () => ({
    dialog: false,

    form: {
      delete_future_transactions: 0,
      transaction_group_id: null,
    },
  }),

  computed: {},

  methods: {
    open() {
      this.reset();
      this.dialog = true;
    },

    reset() {
      this.form.transaction_group_id = this.transaction.transaction_group_id;
      this.form.delete_future_transactions = 0;
    },

    async handleDelete() {
      this.$loading.start();
      await this.$http.destroy("transactions/delete", this.transaction.id, this.form)
        .then((response) => {
          this.$loading.finish();
          this.$emit("delete");
          this.dialog = false;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style></style>
