export default class Form {

  reset() {
    for (let field in this.defaultParams) {
      this[field] = this.defaultParams[field];
    }
  }

  getParams() {
    let data = {};
    
    for (let property in this.defaultParams) {
      data[property] = this[property];
    }
    
    return data;
  }

  setParams(data) {
    for (let property in this.defaultParams) {
      if (data[property]) {
        this[property] = data[property];
      }
    }
  }
}
