<template>
  <v-dialog
    persistent
    scrollable
    width="1200"
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title class="d-flex justify-space-between align-center">
        <div>
          <h4>Gerar documento</h4>
        </div>
        <div v-if="form.id">
          <v-btn
            v-if="shareLinkSupported"
            @click="share()"
            color="primary"
            icon
          >
            <v-icon> mdi-share-variant </v-icon>
          </v-btn>

          <v-btn @click="download()" color="primary" icon>
            <v-icon> mdi-download </v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-2">
          <v-col>
            <DocumentSelect
              @input="handleSelectDocument($event)"
              :documentType="documentType"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <app-text-field
              v-model="form.document_name"
              label="Adicione uma descrição"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <AppEditor v-model="form.text" ref="AppEditor" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <app-back-btn @click="dialog = false" />
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
        <app-confirm-btn @click="handleSave()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DocumentSelect from "../../documents/ui/DocumentSelect";
import DocumentMixin from "@/mixins/Document/DocumentMixin";
import AppEditor from "@/components/app/forms/AppEditor";

export default {
  mixins: [DocumentMixin],

  components: {
    DocumentSelect,
    AppEditor,
  },

  props: {
    documentType: {
      default: null,
    },

    customer: {},

    transaction: {},

    session_group: {},

    documentData: {
      default: null,
    },
  },

  data() {
    return {
      form: {},

      baseForm: {
        customer: null,
        text: null,
      },

      documentTemplate: null,

      dialog: false,
      shareLinkSupported: false,
    };
  },

  computed: {
    isInsert() {
      return !this.form.id;
    },

    professional() {
      if (this.$acl.isClinic()) {
        this.getProfessional();
      }

      return this.user;
    },

    user() {
      return this.$store.state.auth.user;
    },

    docName() {
      return `${this.form.document_name}-${this.form.customer.name}.pdf`;
    },
  },

  created() {
    this.reset();

    this.shareLinkSupported = "share" in navigator;
  },

  methods: {
    open(id = null) {
      this.reset();

      if (id) {
        this.showDocument(id);
      }

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
      this.form.customer = this.customer;
      this.form.transaction = this.transaction;
    },

    handleSelectDocument(document) {
      this.$http.show("document/document", document.id).then((response) => {
        this.documentTemplate = response.document.text;
        this.parseDocument();
      });
    },

    getProfessional() {
      if (this.session_group?.professional_id) {
        return this.session_group.professional;
      }

      if (this.transaction?.professional_id) {
        return this.session_group.professional;
      }

      if (this.customer?.professional_id) {
        return this.customer.professional;
      }
    },

    parseDocument() {
      let message = this.documentTemplate;

      message = this.parseCommon(message);

      if (this.customer) {
        message = this.parseCustomer(message, this.customer);
      }

      if (this.professional) {
        message = this.parseProfessional(message, this.professional);
      }

      if (this.transaction) {
        message = this.parseTransaction(message, this.transaction);
      }
      if (this.session_group) {
        message = this.parseSessionGroup(message, this.session_group);
      }

      this.form.text = message;
    },

    showDocument(document_id) {
      this.$loading.start();
      this.$http
        .show("customer/customer-documents", document_id)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.document));

          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleSave() {
      this.$loading.start();
      if (this.isInsert) {
        this.store();
      } else {
        this.update();
      }
    },

    store() {
      this.$http
        .store("customer/customer-documents", this.form)
        .then((response) => {
          this.processSaved(response.customer);
        })
        .catch((response) => {
          this.$loading.finish();
        });
    },

    update() {
      this.$http
        .update("customer/customer-documents", this.form.id, this.form)
        .then((response) => {
          this.processSaved();
        })
        .catch((response) => {
          this.$loading.finish();
        });
    },

    close() {
      this.dialog = false;
    },
    processSaved(customer) {
      this.$emit("store", customer);
      this.$loading.finish();
      this.dialog = false;
    },
    share() {
      this.$refs.AppEditor.share(this.docName);
    },
    download() {
      this.$refs.AppEditor.download(this.docName);
    },
  },
};
</script>


<style>
</style>