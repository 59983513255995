<template>
  <div>
    <template v-if="!isErrored">
      <v-icon color="#00A884" small class="mr-2">mdi-whatsapp</v-icon>
      <small style="color: #00a884">
        {{ statuses[notificationStatus].text }}</small
      >
      <v-icon
        class="ml-2"
        v-if="statuses[notificationStatus].statusIcon"
        :color="statuses[notificationStatus].statusIconColor"
        small
      >
        {{ statuses[notificationStatus].statusIcon }}
      </v-icon>
    </template>

    <template v-if="isErrored">
      <v-icon color="error" small left> mdi-alert-circle </v-icon>
      <small> Falha ao enviar mensagem | {{ errorNumber }} </small>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    notification: {},
  },

  data: () => ({
    statuses: {
      willNotify: {
        text: "A notificação será enviada 1 dia antes da sessão",
      },
      error: {
        text: "Erro ao enviar notificação",
        statusIcon: "mdi-alert-circle",
        statusIconColor: "error",
      },
      sending: {
        text: "Enviando notificação ",
        statusIcon: "mdi-clock-outline",
        statusIconColor: "gray",
      },
      pending: {
        text: "Enviando notificação ",
        statusIcon: "mdi-clock-outline",
        statusIconColor: "gray",
      },
      success: {
        text: "Notificação enviada",
        statusIcon: "mdi-check",
        statusIconColor: "gray",
      },
      RECEIVED: {
        text: "Notificação  recebida",
        statusIcon: "mdi-check-all",
        statusIconColor: "gray",
      },
      DELIVERY_ACK: {
        text: "Notificação  recebida",
        statusIcon: "mdi-check-all",
        statusIconColor: "gray",
      },
      READ: {
        text: "Notificação  lida",
        statusIcon: "mdi-check-all",
        statusIconColor: "info",
      },
    },

    errors: {
      "Phone number does not exist": "Número de telefone não existe",
      "O número consultado não existe no WhatsApp.":
        " | Número de telefone não existe",
      "Phone is empty": "Whatsapp vazio",
    },
  }),

  computed: {
    hasWhatsappId() {
      return this.notification.whatsapp_id != null;
    },
    isNotified() {
      return this.notification != null;
    },

    notificationStatus() {
      if (!this.isNotified) {
        // return "willNotify";
      }

      if (this.notification.whatsapp_status) {
        return this.notification.whatsapp_status;
      }

      return this.notification.status;
    },
    isErrored() {
      return this.isNotified && !this.hasWhatsappId;
    },
    errorNumber() {
      var hasResponse = this.notification?.response != null;

      if (hasResponse && JSON.parse(this.notification?.response).error) {
        return this.getErroMessage();
      }
    },
  },

  methods: {
    getErroMessage() {
      var err = JSON.parse(this.notification?.response);

      if (err.message) {
        return this.errors[err.message];
      }

      if (err.error) {
        return this.errors[err.error];
      }
      return this.errors[err.error];
    },
  },
};
</script>
<style>
</style>