<template>
  <div>
    <template v-for="(document, index) in customerDocuments">
      <v-btn
        text
        class="mt-2"
        color="info"
        @click="handleViewCustomerDocument(document.id)"
        :key="index"
      >
        <v-icon>mdi-file</v-icon>{{ document.document_name }}
      </v-btn>
    </template>
    <!-- <DocumentViewDialog ref="DocumentViewDialog"/> -->
  </div>
</template>

<script>
// import DocumentViewDialog from '../../documents/sections/DocumentViewDialog'
export default {
  // components: { DocumentViewDialog },
  props: {
    customerDocuments: {
      default: () => [],
    },
  },

  methods: {
    handleViewCustomerDocument(id) {
      this.$doc.printView(`/documento/imprimir/${id}`);
      // this.$refs.DocumentViewDialog.open(id)
    },
  },
};
</script>

<style>
</style>