<template>
  <app-select
    label="Modelo de Documento"
    v-bind="$attrs"
    :dense="dense"
    v-on="$listeners"
    :items="filteredDocuments"
    :item-text="itemText"
    :item-value="itemValue"
  />
</template>

<script>
export default {
  props: {
    documentType : {
      default : null
    },

    itemText: {
      default() {
        return (val) => val.name;
      },
    },

    itemValue: {
      default() {
        return (val) => val;
      },
    },

    dense: {
      default: true,
    },
  },

  data : () => ({
    documents : []
  }), 

  created() {
    this.select();
  },

  computed : {
    filteredDocuments () {
      if (this.documentType) {
        return this.documents.filter(document => document.type == this.documentType )
      }

      return this.documents
    }
  },

  methods: {
    select() {
      this.$http.index("document/document").then((response) => {
        this.documents = response.documents;
      });
    },
  },
};
</script>
