<template>
  <v-dialog v-model="dialog" persistent width="700px" :fullscreen="$vuetify.breakpoint.xsOnly">
    <v-card>
      <v-card-title primary-title> Enviar Cobrança </v-card-title>
      <v-card-text>
        <v-row class="mt-1">
          <v-col md="6">
            <app-whatsapp-field v-model="form.phone_wa" :label="labelPhoneWa" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea outlined v-model="form.message" />
          </v-col>
        </v-row>
        <template v-if="notifications.length">
          <v-row>
            <v-col class="pb-0">
              <h3>Notificações Enviadas</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <v-list two-line>
                <v-list-item-group>
                  <v-list-item class="pl-0" v-for="(notification, index) of notifications" :key="index" two-line dense>
                    <v-list-item-content>
                      <v-list-item-title>
                        <TransactionsNotificationStatus :notification="notification" />
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Notificação enviada
                        {{ $format.dateTimeBr(notification.created_at) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </template>
      </v-card-text>

      <v-card-actions>
        <app-back-btn @click="dialog = false" />
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
        <app-confirm-btn @click="handleSendBillingNotification()">
          Enviar
        </app-confirm-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DocumentMixin from "@/mixins/Document/DocumentMixin";
import TransactionsNotificationStatus from "@/components/transactions/sections/TransactionsNotificationStatus.vue";
import { customerAlias } from "@/utils/customerAlias";

export default {
  mixins: [DocumentMixin],

  components: {
    TransactionsNotificationStatus,
  },

  data() {
    return {
      customerAlias: customerAlias(),
      baseForm: {
        transaction_id: null,
        message: null,
        phone_wa: null,
      },

      form: {},
      transaction: {
        session_group: {
          professional: {},
        },
        customer: {
          financial_responsible_whatsapp: null,
          financial_responsible_name: null,
        },
      },
      notifications: [],

      dialog: false,
    };
  },
  computed: {
    transaction_billing_message() {
      return this.$store.state.auth.company.transaction_billing_message;
    },
    professional() {
      return this.$acl.isClinic()
        ? this.transaction.session_group.professional
        : this.$store.state.auth.user;
    },
    financial_responsible() {
      var customer = this.transaction.customer;

      return {
        name: customer.financial_responsible_name,
        phone_wa: customer.financial_responsible_whatsapp,
      };
    },

    hasFinancialResponsible() {
      return (
        this.financial_responsible.name != null ||
        this.financial_responsible.phone_wa
      );
    },
    labelPhoneWa() {
      return this.hasFinancialResponsible
        ? "WhatsApp do Responsável Financeiro"
        : "WhatsApp do " + this.customerAlias;
    },
  },
  created() {
    this.reset();
  },

  methods: {
    open(data) {
      this.reset();
      this.setData(data);
      this.getNotifications();
      this.parseDocument();
      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },
    parseDocument() {
      let message =
        this.transaction_billing_message ??
        "Mensagem de cobrança não configurada !";

      message = this.parseCommon(message);

      if (this.transaction.customer) {
        message = this.parseCustomer(
          message,
          this.hasFinancialResponsible
            ? this.financial_responsible
            : this.transaction.customer
        );
      }

      message = this.parseProfessional(message, this.professional);

      if (this.transaction) {
        message = this.parseTransaction(message, this.transaction);
      }

      this.form.message = message;
    },

    getNotifications() {
      this.$loading.start();
      this.$http
        .index("transactions/transactions-notification", {
          transaction_id: this.transaction.id,
        })
        .then((response) => {
          this.$loading.finish();
          this.notifications = response.notifications;
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    handleSendBillingNotification() {
      this.$loading.start();
      this.$http
        .$post("transactions/transactions-notification", this.form)
        .then((response) => {
          this.$loading.finish();
          this.$snackbar({
            message: "Cobrança Enviada",
            color: "success",
            showClose: true,
          });
          this.dialog = false;
          this.$emit("store");
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    setData(data) {
      this.transaction = data;
      this.form = {
        transaction_id: data.id,
        phone_wa: this.setPhoneWa(data.session_group.customer),
      };
    },

    setPhoneWa() {
      return this.hasFinancialResponsible
        ? this.financial_responsible.phone_wa
        : this.transaction.customer.phone_wa;
    },
  },
};
</script>

<style></style>