<template>
  <v-chip x-small :color="statuses[value].color">
    {{ statuses[value].text }}
  </v-chip>
</template>

<script>
export default {
  props: {
    value: {},
  },

  data: () => ({
    statuses: {
      pending: {
        text: "Pendente",
        color: "primary",
      },
      shown: {
        text: "Compareceu",
        color: "success",
      },
      canceled: {
        text: "Cancelada",
        color: "error",
      },
      missed: {
        text: "Não compareceu",
        color: "error",
      },
      suspended: {
        text: "Suspensa",
        color: "error",
      },
    },
  }),
};
</script>

<style></style>
